import { Fragment, useState, useEffect } from "react";
import { request } from "../utilities/fetch";
import { useParams } from "react-router-dom";
import Skeleton from "./common/skeleton";
import Notice from "./common/notice";
import FetchError from "./common/fetchError";
import ApiForm from "./common/formApiEmbed";
import FileLibraryLinks from "./common/fileLibraryLinks";
import YouTubeEmbed from "./common/youTubeEmbed";
import ContractSign from "./common/contractSign";
import ModalContent from "./common/modalContent";
import BackButton from "./common/backButton";
import Spinner from "./common/spinner";
import UserFileLibrary from "./pages/components/userFileLibrary";
import MainPagePDFButton from "./mainPagePDFButton";
import utils from "../utilities";
import useParseContent from "./hooks/useParseContent";

import { useFetchArticle } from "./hooks/fetch/useFetchPages";
import useStore from "./hooks/useStore";
import useCustomNavigate from "./hooks/useCustomNavigate";

import("../../scss/components/contracts.scss");

const MainPageContent = ({
  articleId,
  userId,
  isModal,
  training = false,
  onClose,
}) => {
  const { pageSlug: reqPageSlug } = useParams();
  const navigate = useCustomNavigate();
  const articleSlugOrId = articleId || reqPageSlug;
  const [pageSlug, setPageSlug] = useState(articleSlugOrId);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [isContractSubmitting, setIsContractSubmitting] = useState(false);
  const setPageTitle = useStore((state) => state.setPageTitle);
  const { data, isLoading, error, isError } = useFetchArticle({
    slug: pageSlug,
    callback: () => {
      utils.interceptAnchorLinks(".main-content--scrollbox");
    },
  });

  useEffect(() => {
    data && setPageTitle(data?.description);
  }, [data]);

  useEffect(() => {
    // custom scrollbar on content area
    if (!isModal) {
      require("simplebar");
      setPageSlug(articleSlugOrId);
    }
  }, [articleSlugOrId]);

  const imageUrl = data?.content && data?.content[0]?.image?.imageUrl;
  const preventCrop = data?.content && !!data?.content[0]?.image?.preventCrop;
  const button = data?.content ? <MainPagePDFButton /> : "";
  const headingClass =
    data && !isLoading
      ? "main-content--heading"
      : "main-content--heading h1__skeleton";
  const content = useParseContent(data?.content, userId);

  const allContentStr = content
    ?.map((section) => {
      return section.content;
    })
    ?.join(" ");

  const hasMissingSubstitutions =
    allContentStr?.includes("class='text-missing'") ||
    allContentStr?.includes('class="text-missing"');

  const doSignContract = async () => {
    setIsContractSubmitting(true);
    try {
      await request({
        url: `/scripts/sign-document/${data?.id}`,
      });
      setIsContractSigned(true);
      setIsContractSubmitting(false);
    } catch (err) {
      setIsContractSigned(true);
      setIsContractSubmitting(false);
    }
  };

  const hasForms = content?.some((section) => section?.contentType === "form");

  const signedMessage = data?.signed
    ? `You signed this document on ${utils.getFormattedDateTime(data.signed)}`
    : "Thank you for signing this document.";

  const missingInfoMessage =
    "This document cannot be signed as your profile is currently missing some information (highlighted)";

  const isBusy = !!isLoading;
  const pageTitle = training ? "Training videos" : data?.description;
  const subTitle = training ? (
    <div className="flex flex__middle">
      <h2>{data?.description}</h2>
      <BackButton
        label="Back to videos"
        onClick={() => navigate("/training")}
      />
    </div>
  ) : null;

  // disable contract signing if viewing as modal
  if (isModal && data) data.isContract = false;
  const articleContent = (
    <>
      <div className="main-content--content-column">
        <h1 className={headingClass}>
          {!isLoading && !isError && (data || isError) ? (
            pageTitle
          ) : (
            <Skeleton lines="1" />
          )}
        </h1>
        <div className="main-content--scrollbox">
          <div
            className="main-content--content-container"
            data-simplebar={!!!isModal}
          >
            <div className="main-content--content-content">
              {subTitle}
              {!isLoading && !isError && content
                ? content.map((section, key) => {
                    return (
                      <Fragment key={key}>
                        {section?.contentType !== "form" &&
                          section?.contentType !== "youtube" &&
                          section?.contentType !== "filesFolder" && (
                            <div
                              key={key}
                              dangerouslySetInnerHTML={{
                                __html: section.content,
                              }}
                              className="main-content--content-section"
                            ></div>
                          )}
                        {section?.contentType === "youtube" &&
                          section?.youtubeUrl && (
                            <div
                              key={key}
                              className="main-content--content-section"
                            >
                              <YouTubeEmbed url={section?.youtubeUrl} />
                            </div>
                          )}

                        {section?.contentType === "filesFolder" &&
                          section?.filesFolderId && (
                            <div
                              key={key}
                              className="main-content--content-section"
                            >
                              <FileLibraryLinks id={section?.filesFolderId} />
                            </div>
                          )}

                        {section?.contentType === "form" &&
                          (!data?.isContract || !data?.signed) &&
                          section?.formId && (
                            <div
                              key={key}
                              className="main-content--content-section"
                            >
                              <ApiForm
                                id={section?.formId}
                                articleId={data?.id}
                                isContract={data?.isContract}
                                disabled={isModal}
                                contractSigned={
                                  data?.signed || hasMissingSubstitutions
                                }
                                onSignContract={doSignContract}
                              />
                            </div>
                          )}
                      </Fragment>
                    );
                  })
                : !isError && <Skeleton lines="50" contain />}

              {isContractSubmitting && <Spinner />}

              {!isLoading &&
                !isError &&
                data?.isContract &&
                (data?.signed || isContractSigned) &&
                !isContractSubmitting && (
                  <Notice type="info" message={signedMessage} />
                )}

              {(!hasForms || hasMissingSubstitutions) &&
                data?.isContract &&
                !data?.signed &&
                !isContractSubmitting &&
                !isContractSigned && (
                  <>
                    {hasMissingSubstitutions ? (
                      <Notice type="alert" message={missingInfoMessage} />
                    ) : (
                      <ContractSign
                        disabled={isBusy}
                        onSubmit={doSignContract}
                      />
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      {!isError && imageUrl && (
        <div className="main-content--image-column">
          {!isLoading && !isError && imageUrl ? (
            <>
              <img
                src={imageUrl}
                srcSet={`${imageUrl}?size=tiny 50w,
                          ${imageUrl}?size=small 200w,
                          ${imageUrl}?size=medium 500w,
                          ${imageUrl}?size=large 1000w,
                          ${imageUrl}?size=full 2000w`}
                sizes="(max-width: 1300px) 92vw, (min-width: 1300px) 21vw"
                alt=""
                className="main-content--image"
                data-no-crop={preventCrop}
              />
            </>
          ) : (
            isLoading &&
            !isError && (
              <Skeleton image className="main-content--image" height="20rem" />
            )
          )}
        </div>
      )}
    </>
  );

  if (isModal) {
    return (
      <ModalContent
        type="content"
        content={articleContent}
        isWide={true}
        onClose={onClose}
      />
    );
  }

  const errorStatus = error?.status ? error.status : null;
  return (
    <div
      className={
        imageUrl ? "main-content" : "main-content main-content__no-image"
      }
    >
      {errorStatus === 204 && (
        <Notice message="This page is currently under construction" />
      )}
      {errorStatus === 404 && (
        <FetchError error="Page not found" status={404} />
      )}
      {errorStatus && errorStatus !== 404 && errorStatus !== 204 && (
        <FetchError error={error?.message} status={error?.status} />
      )}

      {!isError && !errorStatus && <>{articleContent}</>}
      {!isLoading &&
        !isError &&
        !errorStatus &&
        !data?.underConstruction &&
        button}

      {data?.userFileLibraryEnabled && data?.userFileLibrarySlug && (
        <>
          <UserFileLibrary
            title="Downloads"
            slug={data?.userFileLibrarySlug}
            readOnly={true}
            hasFolders={false}
          />
        </>
      )}
    </div>
  );
};

export default MainPageContent;
