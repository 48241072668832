import { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import "../../../../scss/components/userFileLibrary.scss";
import { FolderAdd, CloudUpload, Download, TrashCan } from "akar-icons";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import SVG from "../../common/svg";
import Icon from "../../common/icon";
import Skeleton from "../../common/skeleton";
import EditableText from "../../common/editableText";
import utils from "../../../utilities";
import {
  useFetchFileLibraries,
  useFetchFileLibrary,
  useAddUserFiles,
  useDeleteFile,
  useUpdateFile,
  useRenameFolder,
  useDeleteFolder,
} from "../../hooks/fetch/useFetchUserFiles";
import { useFetchMe } from "../../hooks/fetch/useFetchUsers";
import useStore from "../../hooks/useStore";

const imageAccept = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/webp": [".webp"],
  "image/svg+xml": [".svg"],
  "image/avif": [".avif"],
};

const fileAccept = {
  ...imageAccept,
  "video/mp4": [".mp4"],
  "video/ogg": [".ogv"],
  "video/webm": [".webm"],
  "video/3gpp": [".3gp", ".3gpp"],
  "audio/mpeg3": [".mp3"],
  "audio/aac": [".aac"],
  "audio/ogg": [".ogg"],
  "application/vnd.ms-excel": [".xls", ".xlsx"],
  "application/msword": [".doc", ".docx"],
  "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
  "application/pdf": [".pdf"],
  "application/rtf": [".rtf"],
  "text/csv": [".csv"],
  "text/plain": [".txt"],
  "application/zip": [".zip"],
  "application/gzip": [".gzip"],
};

const UserFileLibrary = ({
  user: initUser,
  slug,
  readOnly = false,
  hasFolders = true,
  hasWelcomeText = false,
  title = null,
}) => {
  const user = initUser || {
    id: "0",
  };
  const clientRef = useStore((state) => state.clientRef);

  const isAdmin = useStore((state) => state.getIsAdmin());
  const currentUser = useStore((state) => state.currentUser);
  const { isPayroll } = currentUser;
  const [isBusy, setIsBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [droppedId, setDroppedId] = useState(null);
  const [isDraggingOverFolder, setIsDraggingOverFolder] = useState(null);
  const [path, setPath] = useState("");
  const fileInputRef = useRef(null);

  // Reset path to when user changes
  useEffect(() => {
    setPath("");
  }, [user?.id, slug]);

  const { data: me, isLoading: isMeLoading } = useFetchMe({});

  const { data: libraries, isLoading: isLoadingLibraries } =
    useFetchFileLibraries({ account: clientRef, userId: user?.id });

  const { mutate: updateFile } = useUpdateFile({
    account: clientRef,
    userId: user?.id,
    callback: () => {
      refetchFiles();
    },
  });

  useEffect(() => {
    setIsLoading(!!(isMeLoading || isLoadingLibraries));
  }, [isMeLoading, isLoadingLibraries]);

  // Need to check if user is line manager of current user
  // for now just use isAdmin or accessLevel
  const isManager =
    isAdmin || (slug === "payroll" && isPayroll) || me?.accessLevel >= 2;

  // Is current user the same as the user being viewed?
  const isMe = me && user && user?.id === me?.id;

  const library = libraries?.find((library) => library.slug === slug);

  const { mutate: uploadFiles } = useAddUserFiles({
    account: clientRef,
    userId: user?.id,
    libraryId: library?.id,
    path,
    callback: () => {
      setIsBusy(false);
    },
  });
  const { refetch: refetchFiles } = useFetchFileLibrary({
    account: clientRef,
    libraryId: library?.id,
    userId: user?.id,
    callback: () => {
      setIsBusy(false);
      setDroppedId(null);
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    accept: fileAccept,
    onDrop: (files) => {
      if (doUploadFiles && files && files.length > 0) {
        doUploadFiles(files);
      }
    },
    multiple: true,
  });
  const dropTargetProps = getRootProps();
  const fileInputProps = getInputProps();

  const doUploadFiles = async (files) => {
    setIsBusy(true);
    const thisFormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      thisFormData.append("upload", files[i]);
    }
    thisFormData.append("folder", path);
    uploadFiles(thisFormData);
  };
  const doDragFileEnd = (e) => {
    const id = e?.active?.id?.substring(4);
    const overId = e?.over?.id?.substring(4);
    setIsDragging(false);
    setIsDraggingOverFolder(null);
    if (overId) {
      setDroppedId(id);
      setIsBusy(true);
      updateFile({ id, path: overId });
    }
  };
  const doDragOver = (e) => {
    const overId = e?.over?.id;
    if (overId) {
      return setIsDraggingOverFolder(overId?.substring(4));
    }
    setIsDraggingOverFolder(null);
  };

  const settings = {
    access:
      (isMe && library?.settingsUser?.access) ||
      (isManager && library?.settingsAdmin?.access),
    upload:
      (isMe && library?.settingsUser?.upload) ||
      (isManager && library?.settingsAdmin?.upload),
    download:
      (isMe && library?.settingsUser?.download) ||
      (isManager && library?.settingsAdmin?.download),
    edit:
      (isMe && library?.settingsUser?.edit) ||
      (isManager && library?.settingsAdmin?.edit),
    delete:
      (isMe && library?.settingsUser?.delete) ||
      (isManager && library?.settingsAdmin?.delete),
  };
  if (!library?.global && settings.access === false) {
    return null;
  }

  const libraryClass = !isBusy
    ? "user-file-library"
    : "user-file-library user-file-library__busy";

  return (
    <div className={libraryClass}>
      {title && <h1>{title}</h1>}
      <UserFileLibraryToolbar
        library={library}
        userId={user?.id}
        path={path}
        readOnly={readOnly}
        hasFolders={hasFolders}
        fileInputProps={fileInputProps}
        fileInputRef={fileInputRef}
        onIsBusy={setIsBusy}
      />
      <DndContext
        onDragEnd={doDragFileEnd}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragCancel={() => {
          setIsDragging(false);
        }}
        onDragOver={doDragOver}
      >
        <UserFileLibraryFilesFolders
          userId={user?.id}
          libraryId={library?.id}
          path={path}
          readOnly={readOnly}
          dropTargetProps={dropTargetProps}
          isLoading={isLoading}
          isDragging={isDragging}
          isDraggingOverFolder={isDraggingOverFolder}
          droppedId={droppedId}
          fileInputRef={fileInputRef}
          hasFolders={hasFolders}
          hasWelcomeText={hasWelcomeText}
          onChangeFolder={setPath}
          onIsBusy={setIsBusy}
        />
      </DndContext>
    </div>
  );
};

const UserFileLibraryToolbar = ({
  library,
  userId,
  path,
  readOnly,
  hasFolders,
  fileInputProps,
  fileInputRef,
}) => {
  const clientRef = useStore((state) => state.clientRef);
  const [showFolderPrompt, setShowFolderPrompt] = useState(false);

  const { mutate: uploadFiles, isLoading: isUploading } = useAddUserFiles({
    account: clientRef,
    userId,
    libraryId: library?.id,
    path,
    callback: () => {
      setShowFolderPrompt(false);
    },
  });

  const folderNameInput = useRef(null);

  const pathParts = path?.split("/")?.filter((part) => part !== "");

  const doCreateFolder = () => {
    const folderName = folderNameInput?.current?.value;
    if (folderName) {
      const thisFormData = new FormData();
      thisFormData.append("folder", path + folderName);
      uploadFiles(thisFormData);
    }
  };

  const doShowFileUpload = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <div className="user-file-library-toolbar">
        <div className="user-file-library-toolbar--path">
          {pathParts?.length > 0 && (
            <>
              <span className="user-file-library-toolbar--path--item">
                {pathParts.map((part) => {
                  return part + " / ";
                })}
              </span>
            </>
          )}
        </div>
        {!readOnly && (
          <>
            {hasFolders && (
              <button
                className="button button__toolbar"
                title="Create folder"
                aria-label="Create folder"
                onClick={() => {
                  setShowFolderPrompt(true);
                }}
              >
                <FolderAdd />
              </button>
            )}
            <button
              className="button button__toolbar"
              title="Upload files"
              aria-label="Upload files"
              onClick={doShowFileUpload}
            >
              <CloudUpload />
            </button>
          </>
        )}
      </div>

      <Dialog
        header="Header"
        visible={showFolderPrompt}
        closable={true}
        dismissableMask={true}
        className="confirm-dialog"
        footer={
          <>
            <button
              onClick={() => {
                setShowFolderPrompt(false);
              }}
              aria-label="Cancel"
              className="p-button p-component p-confirm-dialog-reject button button__invert"
            >
              <span className="p-button-label p-c">Cancel</span>
            </button>
            {isUploading && (
              <button
                disabled
                aria-label="Create folder (saving)"
                className="p-button p-component p-confirm-dialog-accept button"
              >
                <span className="p-button-label p-c">Creating folder...</span>
              </button>
            )}
            {!isUploading && (
              <button
                onClick={doCreateFolder}
                aria-label="Change password"
                className="p-button p-component p-confirm-dialog-accept button"
              >
                <span className="p-button-label p-c">OK</span>
              </button>
            )}
          </>
        }
        onHide={() => {
          setShowFolderPrompt(false);
        }}
      >
        <>
          <label htmlFor="dialog-input-youtube">
            <h2>Create a folder</h2>
          </label>
          <div className="confirm-inputs">
            <input
              ref={folderNameInput}
              autoFocus={true}
              type="text"
              placeholder="Folder name"
              aria-label="Folder name"
              onKeyUp={(e) => e.key === "Enter" && doCreateFolder()}
            />
          </div>
        </>
      </Dialog>
      <input
        type="file"
        style={{ display: "none" }}
        // disabled={disabled}
        multiple
        accept={fileAccept?.keys?.join(",")}
        {...fileInputProps}
        ref={fileInputRef}
      />
    </>
  );
};

const UserFileLibraryFilesFolders = ({
  libraryId,
  userId,
  path = null,
  dropTargetProps,
  readOnly,
  isLoading,
  isDragging,
  isDraggingOverFolder,
  droppedId,
  hasWelcomeText,
  hasFolders,
  fileInputRef,
  onChangeFolder,
}) => {
  const clientRef = useStore((state) => state.clientRef);
  const fetchProps = {
    account: clientRef,
    libraryId,
    userId,
  };
  const {
    data: library,
    isLoading: isLibraryLoading,
    refetch: refetchFiles,
  } = useFetchFileLibrary(fetchProps);

  useEffect(() => {
    refetchFiles();
  }, [clientRef, libraryId, userId, path]);

  const pathParts = path?.split("/")?.filter((part) => part !== "");
  const files = library?.files?.filter((file) => {
    if (!path || (Array.isArray(pathParts) && pathParts.length === 0)) {
      return !!(!file.path || file.path === "/");
    }
    return file.path === path;
  });

  const folders = library?.folders;
  const foldersRoot = (path &&
    path !== "/" &&
    utils.findNested(folders, "path", path)) || { folders };
  const parentPath = foldersRoot?.parentPath || "";
  const subFolders = (foldersRoot?.folders && [...foldersRoot?.folders]) || [];

  // If we're in a subfolder, add a back button folder
  if (foldersRoot?.path) {
    subFolders.unshift({
      name: "Back",
      type: "back",
      path: parentPath || "/",
    });
  }

  const doShowFileUpload = () => {
    fileInputRef?.current?.click();
  };

  const skeleton = !!(isLoading || isLibraryLoading);

  if (skeleton) {
    return <Skeleton type="files-thumbs" />;
  }

  if (
    (!subFolders || subFolders?.length === 0) &&
    (!files || files?.length === 0) &&
    hasWelcomeText
  ) {
    return (
      <div className="user-file-library-message">
        <button
          className="user-file-library-file-message-button"
          onClick={doShowFileUpload}
        >
          Click to upload files to this page
        </button>
      </div>
    );
  }

  return (
    <>
      {subFolders?.length > 0 && hasFolders && (
        <div
          className="user-file-library-files user-file-library-files__folders"
          {...dropTargetProps}
        >
          <>
            {subFolders?.map((folder, i) => (
              <FolderButton
                folder={folder}
                key={i}
                libraryId={libraryId}
                userId={userId}
                readonly={readOnly}
                isDropActive={isDraggingOverFolder === folder?.path}
                onChangeFolder={onChangeFolder}
              />
            ))}
          </>
        </div>
      )}

      <UserFileLibraryFiles
        files={files}
        libraryId={libraryId}
        userId={userId}
        readOnly={readOnly}
        droppedId={droppedId}
        isDragging={isDragging}
      />
    </>
  );
};

const FolderButton = ({
  folder,
  readOnly,
  libraryId,
  userId,
  key,
  isDropActive,
  onChangeFolder,
}) => {
  const clientRef = useStore((state) => state.clientRef);
  const fetchProps = {
    account: clientRef,
    libraryId,
    userId,
  };
  const { mutate: deleteFolder } = useDeleteFolder(fetchProps);
  const { mutate: renameFolder } = useRenameFolder(fetchProps);

  const { setNodeRef } = useDroppable({
    id: `DROP${folder?.path}`,
    // data: {
    //   accepts: ['type1', 'type2'],
    // },
  });

  const doRenameFolder = (folder, newFolder) => {
    renameFolder({ folder, newFolder });
  };

  const doDeleteFolder = (folder) => {
    confirmDialog({
      message:
        "Are you sure you want to delete this folder, and all its contents?",
      accept: () => {
        deleteFolder({ folder });
      },
      className: "confirm-dialog",
      acceptClassName: "button",
      rejectClassName: "button button__invert",
    });
  };

  return (
    <div
      className="user-file-library-files--item"
      key={key}
      data-folder={folder?.path}
      data-is-drop-active={isDropActive}
      ref={setNodeRef}
    >
      <button
        className="button button__transparent user-file-library-files--item-icon--folder"
        onClick={() => {
          onChangeFolder(folder?.path);
        }}
      >
        <Icon
          type="akar"
          src={"Folder"}
          label={folder?.name}
          className="user-file-library-files--item-icon"
        />
        {folder?.type === "back" && (
          <Icon
            type="akar"
            src={"ArrowBack"}
            label={folder?.name}
            className="user-file-library-files--item-icon-overlay"
          />
        )}
      </button>
      {folder?.type !== "back" && (
        <>
          {readOnly ? (
            <>{folder?.name}</>
          ) : (
            <>
              <div className="user-file-library-files--item--caption">
                {isDropActive ? (
                  folder?.name
                ) : (
                  <EditableText
                    value={folder?.name}
                    truncate={100}
                    split={15}
                    onSave={(value) => {
                      doRenameFolder(folder.path, value);
                    }}
                  />
                )}
              </div>
              {!isDropActive && (
                <div className="user-file-library-files--edit-icons edit-icons edit-icons--hoverable">
                  <button
                    className="button button__condensed"
                    aria-label="Delete folder"
                    title="Delete folder"
                    onClick={() => {
                      doDeleteFolder(folder.path);
                    }}
                  >
                    <TrashCan size={22} strokeWidth={1} />
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const UserFileLibraryFiles = ({
  files,
  libraryId,
  userId,
  readOnly,
  droppedId,
  isDragging,
}) => {
  return (
    <div className="user-file-library-files">
      {files?.map(
        (file, i) =>
          droppedId != file?.id && (
            <File
              key={i}
              file={file}
              libraryId={libraryId}
              userId={userId}
              readOnly={readOnly}
              isDragging={isDragging}
            />
          )
      )}
    </div>
  );
};

const File = ({ file, libraryId, userId, readOnly, isDragging }) => {
  const id = `DRAG${file?.id}`;
  let { attributes, listeners, setNodeRef, transform } = useDraggable({ id });
  const clientRef = useStore((state) => state.clientRef);
  const authToken = useStore((state) => state.authToken);
  const currentUser = useStore((state) => state.currentUser);
  const isAdmin = useStore((state) => state.getIsAdmin());
  const fetchProps = {
    account: clientRef,
    libraryId,
    userId,
  };
  const { mutate: deleteFile } = useDeleteFile(fetchProps);
  const { mutate: updateFile } = useUpdateFile(fetchProps);

  const doRenameFile = (fileId, value) => {
    updateFile({ id: fileId, description: value });
  };

  const doDownloadFile = (e, fileId, fileName) => {
    e.preventDefault();
    fetch(
      `${process.env.API_URL}/file-libraries/${fileId}?account=${clientRef}`,
      {
        method: "GET",
        headers: {
          "x-auth-token": authToken,
        },
      }
    )
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doDeleteFile = (fileId) => {
    confirmDialog({
      message: "Are you sure you want to delete this file?",
      accept: () => {
        deleteFile(fileId);
      },
      className: "confirm-dialog",
      acceptClassName: "button",
      rejectClassName: "button button__invert",
    });
  };

  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  };

  if (!currentUser?.isHR && !isAdmin) {
    listeners = {};
    attributes = {};
  }

  return (
    <div
      className="user-file-library-files--item"
      key={file?.id}
      ref={setNodeRef}
      data-id={file?.id}
      style={style}
    >
      <button
        className="button-unstyled user-file-library-files--item-icon"
        {...listeners}
        {...attributes}
      >
        <SVG
          src={utils.getMimeIcon(file?.mime)}
          label={file?.description}
          className="user-file-library-files--item-icon--svg"
        />
      </button>
      <div className="user-file-library-files--item--caption">
        {readOnly ? (
          <>{file?.description}</>
        ) : (
          <EditableText
            value={file?.description}
            truncate={100}
            split={15}
            onSave={(value) => {
              doRenameFile(file.id, value);
            }}
          />
        )}
      </div>
      {!isDragging && (
        <div className="user-file-library-files--edit-icons edit-icons edit-icons--hoverable">
          <button
            className="button button__condensed"
            aria-label="Download file"
            title="Download file"
            onClick={(e) => {
              doDownloadFile(e, file.id, file.fileName);
            }}
          >
            <Download size={22} strokeWidth={1} />
          </button>

          {!readOnly && (
            <button
              className="button button__condensed"
              aria-label="Delete file"
              title="Delete file"
              onClick={() => {
                doDeleteFile(file.id);
              }}
            >
              <TrashCan size={22} strokeWidth={1} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default UserFileLibrary;
